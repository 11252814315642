





















































































import Vue from "vue";
import BtnCreateReference from "@/views/Settings/buttons/BtnCreateReference.vue";
import BtnRemoveNomenclator from "@/views/Settings/buttons/BtnRemoveNomenclator.vue";
import BtnEditReference from "@/views/Settings/buttons/BtnEditReference.vue";

import { mapActions, mapState, mapMutations } from "vuex";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  components: { BtnCreateReference, BtnRemoveNomenclator, BtnEditReference },
  name: "references",
  data() {
    return {
      search: "",
      name: "",
      uuid: "",
      dialogConfirmRemove: false,
      itemTemp: {},
      headers: [
        { text: "Name", align: "start", value: "name", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loading", "nomenclators"]),
  },
  mounted() {
    this.actListNomenclator("HowKnow");
  },
  methods: {
    ...mapActions("crmSettingsModule", [
      "actListNomenclator",
      "actUpdateNomenclator",
    ]),
    ...mapMutations("crmSettingsModule", ["mutNomencators"]),

    async listReferences() {
      const references = (await getAPI(`/sprocedures/listNom/HowKnow`)).data;
      this.mutNomencators(references);
    },
  },
});
