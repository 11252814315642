





























import Vue from "vue";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError } from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
export default Vue.extend({
  name: "btn-edit-reference",
  props: {
    item: Object,
  },
  data() {
    return {
      DialogEdit: false,
      rules,
      titlenom: "Source",
      nameitem: "",
      idItem: 0,
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.idItem = this.item.id;
    this.nameitem = this.item.name;
  },
  methods: {
    ...mapMutations("crmSettingsModule", ["mutNomencators"]),
    ...mapActions("crmSettingsModule", ["actUpdateNomenclator"]),
    cancel() {
      this.DialogEdit = false;
    },
    async listReferences() {
      const refer = (await getAPI(`/sprocedures/listNom/HowKnow`)).data;
      this.mutNomencators(refer);
    },

    update(): void {
      const body = { name: this.nameitem, type: "HowKnow", id: this.idItem };
      this.actUpdateNomenclator(body)
        .then(() => {
          this.listReferences();
          this.cancel();
          notifySuccess(`Source has been updated`);
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
