






































































import Vue from "vue";

import { mapActions, mapState } from "vuex";
import rules from "@/components/account/rules";
import { notifyError, notifyInfo } from "@/components/Notification";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "btn-create-reference",
  props: {
    expanded: Boolean,
  },
  data() {
    return {
      dialog: false,
      formValid: false,

      rules,
      minprice: 0,
      maxprice: 0,
      price: 0,
      loading: false,
      form: {
        name: "",
        type: "HowKnow",
      },
    };
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actCreateSubtypeNomenclator"]),
    create(): void {
      this.loading = true;
      this.actCreateSubtypeNomenclator(this.form)
        .then(() => {
          this.loading = false;
          this.form.name = "";
          (this as any).$refs.form.resetValidation();
          notifyInfo(`Source has been created successfully`);
          this.resetform();
          (this.dialog = false), this.$emit("created");
        })
        .catch((error: any) => {
          this.loading = false;
          this.dialog = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetform() {
      this.form.name = "";
    },
    close() {
      this.dialog = false;
      this.resetform();
    },
  },
});
